import type { DummyFoods } from './dummyFoods'

export const wine: DummyFoods = {
  Wine: {
    Red: ['Red wine'],
    Shaoxing: ['Shaoxing wine'],
    Sherry: ['Sherry wine'],
    White: ['White wine'],
  },
}
