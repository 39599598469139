import type { DummyFoods } from './dummyFoods'

export const butter: DummyFoods = {
  Butter: {
    Almond: ['Almond butter'],
    Cashew: ['Cashew butter'],
    Cocoa: ['Cocoa butter'],
    Normal: ['Butter'],
    'Sunflower seed': ['Sunflower seed butter'],
  },
}
