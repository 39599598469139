class Transformer {
  codeType() {
    return 'TODO'
  }

  identified(raw) {
    return false
  }

  async transform(raw) {
    return 'TODO'
  }

  static buttonCaption() {
    return 'TODO'
  }
}

const CODE_TYPE = {
  RAW: 'RAW',
}

export { CODE_TYPE, Transformer }
